@import "../../colours.scss";

.testimonials {
  margin-bottom: 30px;
  .testimonials-text {
    .quote {
      h3 {
        background: $aqua;
        color: #fff;
        font-size: 24px;
        margin: 10px;
        padding: 2%;
        position: relative;
        text-align: center;
        border-radius: 12px;
        font-weight: 300;
      }
    }
  }
}

.slick-prev:before,
.slick-next:before {
  color: black;
  font-size: 25px;
}

.slick-list {
  color: white;
  border-radius: 12px;
}

@media screen and (max-width: 900px) {
  .slick-prev {
    top: 0;
    left: 10px;
    width: auto;
    height: auto;
    &:before {
      font-size: 30px;
      line-height: 0;
    }
  }
  .slick-next {
    top: 0;
    right: 10px;
    width: auto;
    height: auto;
    &:before {
      font-size: 30px;
      line-height: 0;
    }
  }
}

@media screen and (max-width: 700px) {
  .testimonials {
    h3 {
      font-size: 16px !important;
    }
  }
}