@import "../../colours.scss";

.navbar {
  background-color: $darkBlue;
  padding: 10px 0;
  position: relative; // Add this to make sure the navbar is positioned relative to its children

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      h2 {
        font-family: "Poppins";
        color: $white;
        font-weight: 600;
        font-size: 2rem;
        margin: 0;
      }
    }

    .hamburger {
      display: none;
      background: none;
      border: none;
      color: $white;
      font-size: 24px;
      cursor: pointer;
      position: absolute; // Position the hamburger absolutely within the navbar
       // Adjust as needed
      right: 10px; // Adjust as needed
      z-index: 2; // Make sure it's above other content
      @media screen and (max-width: 850px) {
        display: block;
      }
    }

    .nav-items {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      align-items: center;

      & > div {
        margin: 0 25px;
        a {
          color: $white;
          font-family: "Poppins";
          font-size: 18px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      // Mobile styles
      @media screen and (max-width: 850px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: absolute; // Absolutely position the nav-items
        top: 60px; // Adjust to be below the navbar
        left: 0;
        right: 0;
        display: none;
        background-color: $darkBlue; // Match the navbar background
        z-index: 1; // Ensure it's below the hamburger but above other content
        padding-left: 12px;

        & > div {
          width: 100%; // Make sure the links span the full width of the container
          margin: 10px 0;
        }

        &.open {
          display: flex;
        }
      }
    }
  }
}
