@import "../../colours.scss";

.services {
  p,
  ol,
  .row-content-text {
    font-size: 18px !important;
    line-height: 32px !important;
  }
  @media screen and (max-width: 600px) {
    p {
      font-size: 16px !important;
    }
  }
  .faqs {
    margin: 50px 0;
  }
  .row-title {
    font-weight: 600;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      width: 50%;
    }
    .image {
      display: flex;
      justify-content: center;
      border-radius: 5px;
      img {
        width: 100%;
        max-width: 500px;
        border-radius: 5px;
      }
    }
  }
  .flex-reverse {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 50px;
    & > div {
      width: 50%;
    }

    .image {
      img {
        width: 100%;
        max-width: 500px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .services {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        width: 100%;
      }
      .image {
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          max-width: fit-content !important;
        }
      }
    }
    .flex-reverse {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      & > div {
        width: 100%;
      }

      .image {
        img {
          width: 100%;
          max-width: fit-content !important;
          border-radius: 5px;
        }
      }
    }
  }
}

.second-section {
  background-color: $darkBlue;
  color: $white !important;
  padding: 20px 0;

  h2 {
    margin: 0;
  }
}

.first-section {
  padding: 20px 0;
}

.faqs-container {
  .faqs {
    background-color: $darkBlue;
    .faq-row {
      font-size: 16px !important;
    }
  }
}

.faq-row-wrapper {
  background-color: none !important;
}

@media screen and (max-width: 600px) {
  .second-section {
    p,
    ol {
      font-size: 16px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .faqs-container {
    .faqs {
      font-size: 16px !important;
    }
  }
}
