@import "../../colours.scss";

.button {
  position: relative;
  display: inline-block;
  background-color: $darkBlue;
  color: white;
  // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 15px;
  border: none;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button:hover {
  -webkit-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
  background-color: $white;
  color: $darkBlue;
}

.button:hover::after {
  opacity: 1;
}

@media screen and (max-width: 900px) {
  .button {
    font-size: 19px !important;
  }
  
}