@import "../../colours.scss";

.why-us {
  p,
  ul {
    font-size: 18px !important;
    line-height: 32px !important;
  }

  h2 {
    margin: 0;
  }

  .credit-images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    img {
      width: 20%;
      max-width: 300px;
    }
  }

  .card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
      background-color: $darkBlue;
      color: $white;
      padding: 20px;
      margin: 20px 0;
      border-radius: 7px;
      width: 45%;
    }
    @media screen and (max-width: 600px) {
      .card {
        width: 100%;
        p {
          font-size: 16px !important;
        }
      }
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px auto;
    & > div {
      width: 50%;
    }
    .image {
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        max-width: 500px;
        border-radius: 5px;
      }
    }
  }
  .flex-reverse {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 50px;
    background-color: $darkBlue;
    color: $white;
    padding: 20px 0;

    .image {
      img {
        width: 100%;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .why-us {
    .credit-images {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      img {
        width: 100%;
        margin: 20px 0;
      }
    }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        width: 100%;
      }
      .image {
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          max-width: fit-content !important;
        }
      }
    }
    .flex-reverse {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      p {
        font-size: 16px !important;
      }
      .image {
        img {
          width: 100%;
          max-width: fit-content !important;
        }
      }
    }
  }

  .testimonials-text {
    h2 {
      margin: 45px;
      text-align: center;
    }
  }
}
